import { config } from "/app.config"
import { getUserProfile } from "@/services/UserService"

export const CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID

export const isCrispChatEnabled = () => {
  return CRISP_WEBSITE_ID && CRISP_WEBSITE_ID.length && config.isZeno
}

const isCrispChatInitialized = () => {
  return window.$crisp !== undefined
}

export const initializeCrispChat = async (account) => {
  if (!isCrispChatEnabled()) {
    return
  }

  if (!isCrispChatInitialized()) {
    const script = document.createElement("script")
    script.setAttribute("type", "text/javascript")
    script.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="${CRISP_WEBSITE_ID}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`
    document.head.appendChild(script)
  }

  setCrispSessionData(account)
  showCrispChatBox()
}

export const showCrispChatBox = () => {
  if (!isCrispChatInitialized()) {
    return
  }

  window.$crisp.push(["do", "chat:show"])
}

export const hideCrispChatBox = () => {
  if (!isCrispChatInitialized()) {
    return
  }

  window.$crisp.push(["do", "chat:hide"])
}

const setCrispSessionData = async (account) => {
  if (!isCrispChatInitialized()) {
    return
  }

  const profile = await getUserProfile()
  if (profile) {
    window.$crisp.push(["set", "user:email", [profile.email, profile.crispToken]]);
    if (profile.basic_info) {
      window.$crisp.push(["set", "user:nickname", [profile.basic_info.first_name + " " + profile.basic_info.last_name]]);
    } else {
      window.$crisp.push(["set", "user:nickname", [profile.email]])
    }
  }

  if (account) {
    window.$crisp.push(["set", "user:company", [account.company]]);
    window.$crisp.push(["set", "session:data", ["account_key", account.key]]);
  }
}
